import Header from "../../components/header/Header";
import MapBoxWrapper from "../../mapBoxWrapper/MapBoxWrapper";
import Footer from "../../components/footer/Footer";
import { PropTypes } from 'prop-types';
import React, {useEffect, useRef, useState} from "react";
import MixPanel from "../../setup/mixPanel";
import mapboxgl from "mapbox-gl";
import {INITIAL_VIEW_STATE} from "../../resources/constants";
import SelectDropdown from "../../components/select/SelectDropdown";
import colorsAndFonts from '../../resources/colors-and-fonts.scss';
import ReactDOM from "react-dom";
import MapMarkerPopup from "../../components/popups/MapMarkerPopup";


export default function SelectionWrapper(props) {
  let mapContainer = null;
  let userLocation = null;
  let map = useRef(null);
  let popupActive = null;

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: !props.prevSelectedLocker ? [INITIAL_VIEW_STATE.lng, INITIAL_VIEW_STATE.lat] : [props.prevSelectedLocker.Longitude, props.prevSelectedLocker.Latitude],
      zoom: !props.prevSelectedLocker ?  INITIAL_VIEW_STATE.zoom : 12
    });
    if(props.parcelLockers) {
      map.current.on('load', function() {
        onMapLoad();
      });
    }
  });

  function onMapLoad () {
    addMapControls();
    map.current.addSource('lockers', {
      type: 'geojson',
      data: {
        type:"FeatureCollection",
        features: getStopGeoFeatures(props.parcelLockers)
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 30 // Radius of each cluster when clustering points (defaults to 50)
    });

    map.current.addLayer({
      id: 'clusters',
      type: 'circle',
      source: 'lockers',
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': colorsAndFonts.kashmir_blue,
        'circle-radius': 20,
      }
    });

    map.current.addLayer({
      id: 'cluster-count',
      type: 'symbol',
      source: 'lockers',
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 15,
      },
      paint: {
        'text-color': colorsAndFonts.lighter_text
      }
    });

    map.current.addLayer({
      id: 'unclustered-point',
      type: 'circle',
      source: 'lockers',
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-color': colorsAndFonts.kashmir_blue,
        'circle-radius': 7,
        'circle-stroke-width': 2,
        'circle-stroke-color': '#fff'
      }
    });

    map.current.on('click', 'clusters', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map.current.getSource('lockers').getClusterExpansionZoom(
        clusterId,
        (err, zoom) => {
          if (err) return;

          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    });

    map.current.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const lockerData = {
        ID: e.features[0].properties.id,
        Address: e.features[0].properties.address,
        Name: e.features[0].properties.name,
        WorkingHours: e.features[0].properties.workingHours,
        Town: e.features[0].properties.town,
        TownID: e.features[0].properties.townId,
        Latitude: coordinates[1],
        Longitude: coordinates[0]
      };

      const placeholder = document.createElement('div');
      ReactDOM.render(<MapMarkerPopup parcelLocker={lockerData} keyOrder={['Address', 'Town', 'WorkingHours']}
                                              selectParcelLocker={props.handleParcelLockerSelect}/>, placeholder);
      popupActive = new mapboxgl.Popup();
      popupActive.setDOMContent(placeholder).setLngLat(coordinates).addTo(map.current);
    });

    map.current.on('mouseenter', 'clusters', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'clusters', () => {
      map.current.getCanvas().style.cursor = '';
    });

    map.current.on('mouseenter', 'unclustered-point', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });

    map.current.on('mouseleave', 'unclustered-point', () => {
      map.current.getCanvas().style.cursor = '';
    });
  }

  function addMapControls () {
    map.current.addControl(new mapboxgl.ScaleControl({ maxWidth: 150 }), 'bottom-right');
    map.current.addControl(new mapboxgl.NavigationControl());
    userLocation = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    });
    map.current.addControl(userLocation);
    userLocation.on('trackuserlocationstart', function () {
      MixPanel.track('Find my location button clicked');
    });
  }

  function setMapRef (ref) {
    mapContainer = ref;
  }

  function onLockerSelect (locker) {
    if(locker && locker.customAbbreviation) {
      map.current.flyTo({center:[locker.customAbbreviation.Longitude, (parseFloat(locker.customAbbreviation.Latitude) + 0.01).toString()], zoom:12});
      const lockerData = {
        ID: locker.customAbbreviation.ID,
        Address: locker.customAbbreviation.Address,
        Name: locker.customAbbreviation.Name,
        WorkingHours: locker.customAbbreviation.WorkingHours,
        Town: locker.customAbbreviation.Town,
        TownID: locker.customAbbreviation.TownID,
        Latitude: parseFloat(locker.customAbbreviation.Latitude),
        Longitude: parseFloat(locker.customAbbreviation.Longitude)
      };

      if(popupActive) popupActive.remove();

      const placeholder = document.createElement('div');
      ReactDOM.render(<MapMarkerPopup parcelLocker={lockerData} keyOrder={['Address', 'Town', 'WorkingHours']}
                                              selectParcelLocker={props.handleParcelLockerSelect}/>, placeholder);
      popupActive = new mapboxgl.Popup();
      popupActive.setDOMContent(placeholder).setLngLat([lockerData.Longitude, lockerData.Latitude]).addTo(map.current);
    }
  }

  function getStopGeoFeatures () {
    const stopGeoFeatures = [];

    if (props.parcelLockers) {
      props.parcelLockers.forEach(parcelLocker => {

        const feature = {
          type: 'Feature',
          properties: {
            id: parcelLocker.ID,
            address: parcelLocker.Address,
            workingHours: parcelLocker.WorkingHours,
            town: parcelLocker.Town,
            townId: parcelLocker.TownID,
            name: parcelLocker.Name
          },
          geometry: {
            coordinates: [parcelLocker.Longitude, parcelLocker.Latitude],
            type: 'Point'
          }
        };

        stopGeoFeatures.push(feature);
      });
    }

    return stopGeoFeatures;
  }

  return (
    <div className="selection-page-wrapper">
      <Header />
      {props.parcelLockers && props.parcelLockers.length > 0 && (
        <SelectDropdown
          lockersData={props.parcelLockers}
          onLockerSelect={onLockerSelect}
        />
      )}
      <MapBoxWrapper setMapRef={setMapRef} />
      <Footer />
    </div>
  );
}

SelectionWrapper.propTypes = {
  parcelLockers: PropTypes.array,
  handleParcelLockerSelect: PropTypes.func.isRequired,
  prevSelectedLocker: PropTypes.object
};

SelectionWrapper.defaultProps = {
  parcelLockers: null,
  prevSelectedLocker: null
}
