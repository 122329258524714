import * as React from 'react';
import { PropTypes } from 'prop-types';
import './MapBoxWrapper.scss';

export default class MapBoxWrapper extends React.Component {
  render() {
    return (
      <div className={`map-box-wrapper${this.props.selected ? ' selected' : ''}`}>
        <pre className="region-displayer" id="region-displayer" />
        <div className="map-container" ref={this.props.setMapRef} />
      </div>
    );
  }
}

MapBoxWrapper.propTypes = {
  setMapRef: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

MapBoxWrapper.defaultProps = {
  selected: false
}
