import React from 'react';
import './Footer.scss';

import MilyLogo from '../../resources/MilyLogo.png';

export default function Footer() {

  function goToMilyWebPage() {
    window.open('https://milytech.com/');
  }

  return (
    <div className="footer">
      <div className="footer-content">
        Omogućio <img src={MilyLogo} alt="MilyTech logo" className="mily-logo" onClick={goToMilyWebPage}/>
      </div>
    </div>
  );
};