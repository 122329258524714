import React from 'react';
import PropTypes from 'prop-types';
import './Button.scss';

export default function Button(props) {
  return (
    <div className='mily-button' onClick={props.onClick}>
      {props.text}
    </div>
  );
}

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

Button.defaultProps = {
  onClick: () => {},
  text: ''
};
