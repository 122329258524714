import MixPanel from '../setup/mixPanel';

let mixPanelMessage = null;

function setUnloadListener(message) {
  mixPanelMessage = message;
  window.addEventListener('beforeunload', mixpanelTrigger);
}

function removeUnloadListener() {
  mixpanelTrigger();
  window.addEventListener('beforeunload', mixpanelTrigger);
}

function mixpanelTrigger() {
  if (mixPanelMessage) {
    MixPanel.track(mixPanelMessage);
    mixPanelMessage = null;
  }
}

const MixPanelUtil = {
  setUnloadListener: setUnloadListener,
  removeUnloadListener: removeUnloadListener
};

export default MixPanelUtil;
