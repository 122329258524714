import './SelectedLockerInfo.scss';
import React, {useEffect} from "react";
import { PropTypes } from 'prop-types';
import {FEATURE_KEY_ICON_MAP} from "../../components/popups/mapAdditionalFeaturesData";

export default function SelectedLockerInfo(props) {
  const dataKeys = ['Address','Town','WorkingHours'];

  return (
    <div className="selected-locker-data">
        <div className="data-table">
            <div className="name-wrapper">
                {props.locker.Name}
            </div>
            <div className="popup-content">
              <div>
                {dataKeys.map(key => {
                    if (FEATURE_KEY_ICON_MAP[key] && props.locker[key]) {
                    return (
                      <div className="icon-wrapper" key={key}>
                        <i className={`icon ${FEATURE_KEY_ICON_MAP[key]}`} />
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
              <div>
                {dataKeys.map(key => {
                  if (FEATURE_KEY_ICON_MAP[key] && props.locker[key]) {
                    return (
                      <div className="data-wrapper" key={key}>
                        <div className="data">{`${props.locker[key]}${key==='WorkingHours' ? 'h' : ''}`}</div>
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            </div>
            <div className="change-button">
                <div onClick={props.changeLocker}> Promeni paketomat </div>
            </div>
        </div>
    </div>
  );
};

SelectedLockerInfo.propTypes = {
  locker: PropTypes.object.isRequired,
  changeLocker: PropTypes.func.isRequired
};