import React from 'react';
import './Header.scss';
import DexpressLogo from "../../resources/D Express.png";
import { PropTypes } from 'prop-types';
import CheckCircle from "../checkCircle/CheckCircle";

export default function Header(props) {

  return (
    <div className="header">
      {!props.selected && <div className="left"> Izaberite <span className="company-name">D Express</span> paketomat </div>}
      {!props.selected && <div className="right"> <img src={DexpressLogo} alt="DExpress logo" className="dexpress-logo" /> </div>}
      {props.selected && <div className="success-title">
        <CheckCircle /> <div className="title-text">Uspešno ste izabrali paketomat</div>
      </div>}
    </div>
  );
};

Header.propTypes = {
  selected: PropTypes.bool
}

Header.defaultProps = {
  selected: false
}
