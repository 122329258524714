import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomSelect from './CustomSelect';
import './LockerSelect.scss';
import QueryStringUtil from '../../utils/queryStringUtil';

/**
 * Select for couriers
 *
 * @param {object} props - component props
 * @returns {JSX.Element} Select element
 * @component
 * @alias LockerSelect
 * @category Common
 */
export default function LockerSelect(props) {
  const [lockerId, setLockerId] = useState(QueryStringUtil.getQueryStringValue('lockerId'));
  const [lockerOptions, setLockerOptions] = useState([]);

  /**
   * Create select option
   *
   * @param {string} id - courierId to create option for
   * @returns {object} - select option
   * @function
   */
  const createLockerOption = id => {
    if (id) {
      const locker = props.lockersData.find(c => c.ID === id);
      return {
        value: locker.ID,
        label: `${locker.Name} # ${locker.Address} # ${locker.Town}`,
        customAbbreviation: locker
      };
    }

    return null;
  };

  /**
   * Handle courier change in select
   *
   * @param {object} opt - selected option
   * @function
   */
  const onLockerChange = useCallback(
    opt => {
      const updateQueryString = props.lockersData && props.lockersData.length > 0;
      setLockerId(opt && opt.value);
      if (updateQueryString) {
        QueryStringUtil.setQueryStringValue('lockerId', opt && opt.value);
      }

      if (props.onLockerSelect) {
        props.onLockerSelect(opt);
      }
    },
    [props.lockersData]
  );

  useEffect(() => {
    if (props.lockersData) {
      const options = props.lockersData.map(locker => {
        if (locker.ID) {
          return createLockerOption(locker.ID);
        }
      });

      setLockerOptions(options);
    }

    let option;
    if (QueryStringUtil.getQueryStringValue('lockerId')) {
      option = {
        value: QueryStringUtil.getQueryStringValue('lockerId')
      };
      onLockerChange(option);
    }
  }, [props.lockersData]);

  return (
    <div className="locker-select">
      {props.lockersData && (
        <CustomSelect
          className="select"
          options={lockerOptions}
          onChange={onLockerChange}
          value={lockerOptions.filter(l => l.value === lockerId)}
          placeholder={'Pretražite paketomate'}
        />
      )}
    </div>
  );
}

LockerSelect.propTypes = {
  lockersData: PropTypes.arrayOf(PropTypes.object),
  onLockerSelect: PropTypes.func
};
