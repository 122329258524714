import './CheckCircle.scss';
import React from 'react';

export default function CheckCircle() {
  return (
    <div className="circle-wrapper">
      <div className="dot">
        <i className="icon icon-checkmark" />
      </div>
    </div>
  );
}
