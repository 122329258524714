import React from 'react';
import PropTypes from 'prop-types';
import './SelectDropdown.scss';
import LockerSelect from "./LockerSelect";

export default function SelectDropdown(props) {
  return (
    <div className="select-dropdown-wrapper" >
      <LockerSelect lockersData={props.lockersData} onLockerSelect={props.onLockerSelect}/>
    </div>
  );
}

SelectDropdown.propTypes = {
  lockersData: PropTypes.arrayOf(PropTypes.object).isRequired,
  onLockerSelect: PropTypes.func.isRequired
};
