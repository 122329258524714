import './SelectedLocker.scss';
import React, {useEffect} from "react";
import { PropTypes } from 'prop-types';
import SelectedLockerInfo from "./SelectedLockerInfo";
import MapBoxWrapper from "../../mapBoxWrapper/MapBoxWrapper";
import mapboxgl from "mapbox-gl";
import {MapMarkerUtil} from "../../components/marker/util/mapMarkerUtil";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

export default function SelectedLocker(props) {
  let map = null;
  let mapContainer = null;

  useEffect(() => {
    map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [props.locker.Longitude, props.locker.Latitude],
      zoom: 12,
      dragPan: false
    });
    map.doubleClickZoom.disable();
    map.scrollZoom.disable();
    MapMarkerUtil.addMarkerToMap(map, [props.locker.Longitude, props.locker.Latitude]);
  });

  function setMapRef (ref) {
    mapContainer = ref;
  }

  return (
    <div className="selected-locker-wrapper">
      <div className="selected-locker-data-wrapper">
        <Header selected />
        <SelectedLockerInfo locker={props.locker} changeLocker={props.changeLocker} />
        <Footer />
      </div>
      <div className="map-wrapper">
        <MapBoxWrapper setMapRef={setMapRef} selected />
      </div>
    </div>
  );
};

SelectedLocker.propTypes = {
  locker: PropTypes.object.isRequired,
  changeLocker: PropTypes.func.isRequired
};
