import mapboxgl from 'mapbox-gl';
import React from 'react';
import ReactDOM from 'react-dom';
import MapMarkerPopup from "../../popups/MapMarkerPopup";

function addMarkerToMap(map, coordinates, markerPopupData, selectParcelLocker) {
  const marker = new mapboxgl.Marker({color: '#009bfa'}).setLngLat(coordinates || [0, 0]).addTo(map);
  const placeholder = document.createElement('div');
  if(markerPopupData) {
    ReactDOM.render(<MapMarkerPopup parcelLocker={markerPopupData} keyOrder={['Address', 'Town', 'WorkingHours']}
                                    selectParcelLocker={selectParcelLocker}/>, placeholder);
    const popup = new mapboxgl.Popup().setDOMContent(placeholder);
    marker.setPopup(popup);
  }
  return marker;
}

export const MapMarkerUtil = {
  addMarkerToMap: addMarkerToMap
};
