import React, {useEffect, useRef, useState} from 'react';
import './SelectionPage.scss';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SelectionWrapper from './pages/selection/SelectionWrapper';
import SelectedLocker from './pages/selected/SelectedLocker'
import MixPanel from './setup/mixPanel';
import MixPanelUtil from './utils/mixPanelUtil';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
const parcelLockersApi = process.env.REACT_APP_PARCEL_LOCKERS_API;


export default function SelectionPage() {
  const [parcelLockers, setParcelLockers] = useState(null);
  const [selectedLocker, setSelectedLocker] = useState(null);
  const prevLockerRef = useRef();

  useEffect(() => {
    if(!parcelLockers) {
      MixPanel.track(`Page load - Parcel locker selection`);

      loadParcelLockers().then(lockers => {
        const lockersEnriched = [];
        lockers.forEach((locker,i) => {
          lockersEnriched.push({...locker,index:i});
        })
        setParcelLockers(lockersEnriched);
      });

      MixPanelUtil.setUnloadListener('Page Unload - Parcel locker selection');
      return () => {
        MixPanelUtil.removeUnloadListener();
      };
    }
  },[parcelLockers]);

  useEffect(() => {
    prevLockerRef.current = selectedLocker;
  }, [selectedLocker]);


  function parcelLockerSelected(selectedParcelLocker) {
    window.parent.postMessage({ID: selectedParcelLocker.ID, Address: selectedParcelLocker.Address, Town: selectedParcelLocker.Town, TownID: selectedParcelLocker.TownID}, '*');
    MixPanel.track('Parcel locker selected', selectedParcelLocker);
    setSelectedLocker(selectedParcelLocker);
  }

  function changeLocker () {
    MixPanel.track('Parcel locker changed');
    setSelectedLocker(null);
  }

  async function loadParcelLockers () {
    const parcelLockersFetched = await fetch(parcelLockersApi);
    return await parcelLockersFetched.json();
  }

  return (
    <div className="page-wrapper">
      {!selectedLocker && <SelectionWrapper parcelLockers={parcelLockers} handleParcelLockerSelect={parcelLockerSelected} prevSelectedLocker={prevLockerRef.current}/>}
      {selectedLocker && <SelectedLocker locker={selectedLocker} changeLocker={changeLocker} />}
    </div>
  );
};
