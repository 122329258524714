import React from 'react';
import PropTypes from 'prop-types';
import './MapMarkerPopup.scss';
import { FEATURE_KEY_ICON_MAP } from './mapAdditionalFeaturesData';
import Button from "../../button/Button";

export default function MapMarkerPopup(props) {
  const dataKeys = props.keyOrder;

  return (
    <div className="map-marker-popup">
        <div className="name-wrapper">
            {props.parcelLocker.Name}
        </div>
        {props.keyOrder.length>0 && <div className="popup-content">
          <div>
            {dataKeys.map(key => {
              if (FEATURE_KEY_ICON_MAP[key] && props.parcelLocker[key]) {
                return (
                  <div className="icon-wrapper" key={key}>
                    <i className={`icon ${FEATURE_KEY_ICON_MAP[key]}`} />
                  </div>
                );
              }

              return null;
            })}
          </div>
          <div>
            {dataKeys.map(key => {
              if (FEATURE_KEY_ICON_MAP[key] && props.parcelLocker[key]) {
                return (
                  <div className="data-wrapper" key={key}>
                    <div className="data">{`${props.parcelLocker[key]}${key==='WorkingHours' ? 'h' : ''}`}</div>
                  </div>
                );
              }

              return null;
            })}
          </div>
      </div>}
        <div className="button-wrapper">
            <Button text={'Izaberi'} onClick={() => {props.selectParcelLocker(props.parcelLocker)}} />
        </div>
    </div>
  );
}

MapMarkerPopup.propTypes = {
  parcelLocker: PropTypes.object,
  keyOrder: PropTypes.arrayOf(PropTypes.string),
  selectParcelLocker: PropTypes.func
};

MapMarkerPopup.defaultProps = {
  parcelLocker: null,
  keyOrder: [],
  selectParcelLocker: null
};
