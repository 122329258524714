import React from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Select from 'react-select';
import DefaultSelectStyleUtil from "./defaultSelectStyleUtil";
import {components} from "react-select";
import MixPanel from "../../setup/mixPanel";

export default class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
    this.scrollBarRef = React.createRef();
  }

  customMenu = (props, scrollBarRef) => {
    return (
      <SimpleBar autoHide={false} style={{ maxHeight: 458 }} ref={scrollBarRef}>
        {props.children}
      </SimpleBar>
    );
  };

  menuItemClick = (data) => {
    MixPanel.track('Menu item selected', data.customAbbreviation);
  };

  Option = ({
    ...props
  }) => {
    return <components.Option {...props}>
      <div className="select-item" onClick={this.menuItemClick.bind(this, props.data)}>
        <div className="locker-name">{props.data.customAbbreviation.Name}</div>
        <div className="locker-data-wrapper">
          <i className="icon icon-flag" />
          <div className="data address">{props.data.customAbbreviation.Address}</div>
          <i className="icon icon-office" />
          <div className="data town">{props.data.customAbbreviation.Town}</div>
        </div>
      </div>
    </components.Option>
  };

  SingleValue = ({
      children,
      ...props
    }) => {
    return (
      <components.SingleValue {...props}>
        {`${children.split('#')[0]}`}
      </components.SingleValue>
    );
  };

  NoOptionsMessage = ({
      children,
      ...props
    }) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">Nema opcija za izbor</span>
      </components.NoOptionsMessage>
    )
  };

  handleKeyDown = () => {
    // Timeout is hack stolen from github https://github.com/JedWatson/react-select/issues/780
    setTimeout(() => {
      this.checkFocusedOptionPosition();
      MixPanel.track('Key typed inside select search')
    }, 0);
  };

  handleOpenMenu = () => {
    // Timeout is hack stolen from github
    setTimeout(() => {
      this.checkFocusedOptionPosition();
    }, 0);
  };

  checkFocusedOptionPosition = () => {
    const focusedOptionRef = this.selectRef?.current?.focusedOptionRef;
    if (focusedOptionRef) {
      const focusedStart = focusedOptionRef.getBoundingClientRect().top;
      const focusedEnd = focusedStart + focusedOptionRef.getBoundingClientRect().height;

      const scrollEl = this.scrollBarRef.current.getScrollElement();
      const scrollElStart = scrollEl.getBoundingClientRect().top;
      const scrollElEnd = scrollElStart + scrollEl.getBoundingClientRect().height;

      if (focusedStart < scrollElStart) {
        scrollEl.scrollTo(0, scrollEl.scrollTop - (scrollElStart - focusedStart));
      } else if (focusedEnd > scrollElEnd) {
        scrollEl.scrollTop += focusedEnd - scrollElEnd;
      }
    }
  };

  render() {
    return (
      <Select
        ref={this.selectRef}
        styles={DefaultSelectStyleUtil.selectStyle}
        isClearable
        components={{
          SingleValue: this.SingleValue,
          Option: this.Option,
          MenuList: props => {
            return this.customMenu(props, this.scrollBarRef);
          },
          NoOptionsMessage: this.NoOptionsMessage
        }}
        onKeyDown={this.handleKeyDown}
        onMenuOpen={this.handleOpenMenu}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...this.props}
      />
    );
  }
}
